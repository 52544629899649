<template>
  <span v-show="showSwitch" class="switch-btn" @click="searchSwitch">
    {{switchText}}<i class="el-icon-arrow-down" :class="!isCollapse ? 'up' : ''"></i>
  </span>
</template>
<script>
export default {
  data () {
    return {
      switchText: '展开',
      showSwitch: false
    }
  },
  props: ['isCollapse'],
  methods: {
    searchSwitch () {
      this.switchText = this.isCollapse ? '收起' : '展开'
      this.$emit('switchChange', !this.isCollapse)
      var _searchBtns = document.querySelector('.search-box .search-btns')
      if (this.isCollapse) {
        this.$nextTick(() => {
          var _btnsLeft = document.querySelector('.search-box .search-btns').offsetLeft
          if (_btnsLeft < 30) { // 搜索按钮在最左侧
            _searchBtns.classList.add('fixed')
          } else {
            _searchBtns.classList.remove('fixed')
          }
        })
      } else {
        _searchBtns.classList.remove('fixed')
      }
    },
    detectWitch () {
      if (document.querySelector('.search-box .el-row')) {
        this.$nextTick(() => {
          var _searchBtns = document.querySelector('.search-box .search-btns')
          var _btnsLeft = document.querySelector('.search-box .search-btns').offsetLeft
          if (_btnsLeft < 30) { // 搜索按钮在最左侧
            _searchBtns.classList.add('fixed')
          } else {
            _searchBtns.classList.remove('fixed')
          }
          var _height = document.querySelector('.search-box .el-row').clientHeight
          if (_height > 60) { // 搜索条件大于一行
            this.showSwitch = true
          } else {
            this.showSwitch = false
          }
        })
      }
    }
  },
  mounted () {
    this.detectWitch()
    window.onresize = (e) => {
      this.detectWitch()
    }
  },
  components: {
  }
}
</script>
<style scoped lang="scss">
  @import "../assets/css/variables.scss";
  .switch-btn{
    cursor: pointer;
    font-size: 13px;
    color: $--color-primary;
    margin-left: 12px;
    i{
      transition: all .3s;
    }
    .up{
      transform: rotate(180deg);
    }
  }
</style>
