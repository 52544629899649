<template>
  <div class="activitiesSquare wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">政务服务</el-breadcrumb-item>
      <el-breadcrumb-item>培训活动</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="title">
        <div class="line"></div>
        <div class="text">活动广场</div>
      </div>
      <div class="search-box" :class="!isCollapse ? 'expand' : ''">
        <el-form ref="form" @submit.native.prevent label-width="84px" label-position="right">
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item label="活动类型">
                <el-select v-model="queryTable.condition.activityType">
                  <el-option v-for="item in type" :key="item.itemValue" :label="item.itemDesc" :value="item.itemValue"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="活动状态">
                <el-select v-model="queryTable.condition.activityStatus">
                  <el-option v-for="item in state" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="活动时间">
                <el-date-picker
                  v-model="dateRange"
                  @change="getTime"
                  type="datetimerange"
                  placeholder="请选择活动时间"
                  range-separator="至"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="主办单位">
                <el-input v-model="queryTable.condition.sponsor" placeholder="请输入主办单位" :maxlength="20" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="活动名称">
                <el-input v-model="queryTable.condition.activityName" placeholder="请输入活动名称" :maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>
            <div class="search-btns">
              <el-button type="primary" @click="queryTable.query()">搜索</el-button>
              <el-button @click="reset()">重置</el-button>
              <search-switch :isCollapse="isCollapse" @switchChange="switchChange"></search-switch>
            </div>
          </el-row>
        </el-form>
      </div>
      <div class="sort">
        <div>
          <el-button size="small" class="default" @click="defaultSort">默认排序</el-button>
          <el-button size="small" class="timesort" @click="changeSort"
            >活动时间排序<img src="../../assets/img/icon_px@2x.png" alt="" class="sortImg"
          /></el-button>
        </div>
        <div class="right">
          为您找到 <span class="n">{{ queryTable.count }}</span> 条活动<img src="../../assets/img/arrow_left@2x.png" alt="" class="arrow" />
          <span class="b">{{ queryTable.page }}</span
          ><span class="c">/{{ total }}</span>
          <img src="../../assets/img/arrow_right@2x.png" alt="" class="arrow" />
        </div>
      </div>
      <div class="item" v-for="(item, index) in queryTable.table" :key="index">
        <div :style="{background:'url('+item.activityUrl+')'}" alt="" class="img" @click="goDetail(item.id)" ></div>
        <div class="info">
          <div class="titles" @click="goDetail(item.id)">{{ item.activityName }}</div>
          <div class="labels">
            <div v-for="(v, i) in (item.activityLabel || '').split(',')" :key="i">
              <div class="label" v-if="v">{{ v }}</div>
            </div>
          </div>
          <div class="texts">活动时间: {{ item.activityDate }}</div>
          <div class="texts">举办地点: {{ item.activityAddress }}</div>
          <div class="texts">举办单位: {{ item.sponsor }}</div>
        </div>
        <div class="item-right">
          <div class="top">
            <img src="../../assets/img/icon_rs@2x.png" alt="" class="rs" />
            已报名 <span>{{ item.signUpNum }}</span
            >人
          </div>
          <div class="btn2" v-if="item.activityStatus == 1" @click="goDetail(item.id)">已报名</div>
          <div class="btn1" v-if="item.activityStatus == 2" @click="goDetail(item.id)">可报名</div>
          <div class="btn3" v-if="item.activityStatus == 3">可报名</div>
        </div>
      </div>
      <el-row style="margin-top: 25px">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :page-size="queryTable.pageSize"
          :total="queryTable.count"
          :current-page.sync="queryTable.page"
          @current-change="queryTable.search()"
        ></el-pagination>
      </el-row>
    </div>
    <div style="height: 30px"></div>
  </div>
</template>

<script>
import { QueryTable } from '@/assets/js/QueryTable';
import SearchSwitch from '@/components/SearchSwitch.vue';

export default {
  name: 'activitiesSquare',
  data() {
    return {
      isCollapse: true, // 搜索条件默认收起
      dateRange: [],
      queryTable: new QueryTable('activity', {
        // activityStatus: '',
        activityType: '',
        sponsor: '',
        activityName: '',
        activityDate: '',
        endDate: '',
        orderBy: 1,
      }),
      type: [],
      state: [
        {
          value: '1',
          label: '已报名',
        },
        {
          value: '2',
          label: '未报名',
        },
        {
          value: '3',
          label: '不可报名',
        },
      ],
    };
  },
  components: {
    SearchSwitch,
  },
  created() {
    this.queryTable.search();
    this.$api.getkey({ dictKey: 'activity_type' }).then((res) => {
      this.type = res.result;
    });
  },
  methods: {
    defaultSort() {
      this.queryTable.condition.orderBy = 1;
      this.queryTable.search();
    },
    changeSort() {
      switch (this.queryTable.condition.orderBy) {
        case 1:
          this.queryTable.condition.orderBy = 2;
          break;
        case 2:
          this.queryTable.condition.orderBy = 3;
          break;
        case 3:
          this.queryTable.condition.orderBy = 2;
          break;
      }
      this.queryTable.search();
    },
    reset() {
      this.dateRange = [];
      this.queryTable.reset();
    },
    goDetail(id) {
      this.$router.push({ name: 'ActivityDetail', params: { id } });
    },
    switchChange(val) {
      this.isCollapse = val;
    },
    getTime(val) {
      if (val) {
        this.queryTable.condition.activityDate = val[0];
        this.queryTable.condition.endDate = val[1];
      }else{
            this.queryTable.condition.activityDate ='';
            this.queryTable.condition.endDate = '';
        }
    },
  },
  computed: {
    total() {
      return Math.ceil(this.queryTable.count / this.queryTable.pageSize);
    },
  },
};
</script>

<style lang='scss' scoped>
.activitiesSquare {
  
  .content {
    padding: 20px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    .sort {
      margin-top: 30px;
      display: flex;
      padding-bottom: 15px;
      border-bottom: 1px solid #dddddd;
      justify-content: space-between;
      align-items: center;
      /deep/ .sortImg {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        margin-left: 12px;
      }
      .right {
        font-size: 14px;
        color: #666666;
      }
      .arrow {
        width: 12px;
        height: 12px;
      }
      .arrow:nth-of-type(1) {
        margin-left: 12px;
      }
      .n {
        color: #135694;
      }
      .b {
        color: #333333;
      }
      .c {
        font-size: 12px;
        color: #151515;
        margin-right: 6px;
      }
    }
    .item {
      border-bottom: 1px solid #ddd;
      padding: 19.5px 0;
      display: flex;
      .img {
        height: 160px;
        width: 276px;
        cursor: pointer;
        margin-right: 26px;
      }
      .info {
        flex: 1;
        .titles {
          margin-top: 12px;
          font-size: 18px;
          color: #333333;
          font-weight: 600;
          cursor: pointer;
          margin-bottom: 10px;
        }
        .labels {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .label {
            background: #eaf2f9;
            border-radius: 34px;
            height: 28px;
            line-height: 28px;
            padding: 0 15px;
            font-size: 12px;
            color: #135694;
            letter-spacing: 0.3px;
            margin-right: 16px;
          }
        }
        .texts {
          font-size: 12px;
          color: #666666;
          margin-bottom: 8px;
          line-height: 16px;
        }
      }
      .item-right {
        width: 120px;
        .top {
          text-align: right;
          margin-top: 12px;
          color: #666666;
          span {
            font-size: 18px;
            color: #151515;
          }
        }
        .rs {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          vertical-align: text-top;
        }
        .btn1 {
          background: #135694;
          border-radius: 4px;
          width: 96px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          margin-top: 28px;
          cursor: pointer;
          float: right;
        }
        .btn2 {
          font-size: 14px;
          color: #135694;
          line-height: 19px;
          margin-top: 38px;
          margin-right: 20px;
          cursor: pointer;
          text-align: right;
        }
        .btn3 {
          background: #f7f7f7;
          border: 1px solid #dddddd;
          border-radius: 4px;
          width: 96px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          color: #999999;
          margin-top: 28px;
          float: right;
          cursor: no-drop;
        }
      }
    }
  }
}
</style>
